button.filterBtn {
    color: #000000;
    cursor: pointer;
    padding: 5px 10px;
    display: block;
    margin: 10px 15px;
    margin-left: auto;
    line-height: 2em;
    font-size: 1em;
    font-family: 'Montserrat' ,'Times New Roman', Times, serif;
    outline: none;
    position: relative;
    font-size: 16px;
    border: 2px solid #cfcece;
    background-color: #ffffff;
    border-radius: 10px;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
}

button.filterBtn:hover,
button.filterBtn.hover {
    border-color: #ff3572;
}

span.filtersTitle {
    font-size: 18px;
    font-family: 'Montserrat' ,'Times New Roman', Times, serif;
    letter-spacing: -1px;
    text-decoration: underline;
}

.filtersOption {
    font-size: 16px;
}