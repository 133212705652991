p {
    font-size: 20px;
}

.card {
    margin-bottom: 20px;
}

#showDetails>div {
    margin-top: 5px;
    margin-bottom: 5px;
}

#showDetails label {
    padding: 0.375rem .75rem;
}

#showDetails .form-control.is-valid {
    border-color: #ff9e00 !important;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='%23ff9e00' class='bi bi-exclamation-triangle' viewBox='0 0 16 16'%3e  %3cpath d='M7.938 2.016A.13.13 0 0 1 8.002 2a.13.13 0 0 1 .063.016.146.146 0 0 1 .054.057l6.857 11.667c.036.06.035.124.002.183a.163.163 0 0 1-.054.06.116.116 0 0 1-.066.017H1.146a.115.115 0 0 1-.066-.017.163.163 0 0 1-.054-.06.176.176 0 0 1 .002-.183L7.884 2.073a.147.147 0 0 1 .054-.057zm1.044-.45a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566z'/%3e  %3cpath d='M7.002 12a1 1 0 1 1 2 0 1 1 0 0 1-2 0zM7.1 5.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995z'/%3e%3c/svg%3e") !important;
}

#showDetails .form-select.is-valid {
    border-color: #ff9e00 !important;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e"), url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='%23ff9e00' class='bi bi-exclamation-triangle' viewBox='0 0 16 16'%3e  %3cpath d='M7.938 2.016A.13.13 0 0 1 8.002 2a.13.13 0 0 1 .063.016.146.146 0 0 1 .054.057l6.857 11.667c.036.06.035.124.002.183a.163.163 0 0 1-.054.06.116.116 0 0 1-.066.017H1.146a.115.115 0 0 1-.066-.017.163.163 0 0 1-.054-.06.176.176 0 0 1 .002-.183L7.884 2.073a.147.147 0 0 1 .054-.057zm1.044-.45a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566z'/%3e  %3cpath d='M7.002 12a1 1 0 1 1 2 0 1 1 0 0 1-2 0zM7.1 5.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995z'/%3e%3c/svg%3e") !important;
}

.upcomingShow {
    font-size: 150%;
}

.recordingArchive, .recordingRestore {
    transition: 0.2s;
    user-select: none;
    display: inline-block;
    margin: 5px;
}

.recordingArchive {
    margin-left: 10px;
    padding: 2px 8px;
    border: 2px solid rgb(131, 7, 32);
    background-color: crimson;
    border-radius: 10px;
    color: white;
    cursor: pointer;
}

.recordingRestore {
    margin-left: 10px;
    padding: 2px 8px;
    border: 2px solid rgb(32, 131, 7);
    background-color: rgb(20, 220, 97);
    border-radius: 10px;
    color: black;
    cursor: pointer;
}

.recordingMove {
    margin-left: 10px;
    padding: 2px 8px;
    border: 2px solid rgb(131, 7, 32);
    background-color: crimson;
    border-radius: 10px;
    color: white;
    cursor: pointer;
}

.recordingMoveConfirm {
    margin-left: 10px;
    padding: 2px 8px;
    border: 2px solid rgb(7, 131, 32);
    background-color: green;
    border-radius: 10px;
    color: white;
    cursor: pointer;
}

.recordingMoveCancel {
    margin-left: 10px;
    padding: 2px 8px;
    border: 2px solid rgb(131, 7, 32);
    background-color: crimson;
    border-radius: 10px;
    color: white;
    cursor: pointer;
}

.recordingArchive:hover {
    background-color: rgb(131, 7, 32);
}

.recordingRestore:hover {
    background-color: rgb(32, 131, 7);
}

.recordingArchive:active {
    background-color: rgb(180, 95, 112);
    transition: 0s;
}

.recordingRestore:active {
    background-color: rgb(95, 180, 112);
    transition: 0s;
}