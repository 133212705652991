#loginBox {
    background-color: crimson;
    border: 3px solid rgb(148, 5, 34);
    border-radius: 15px;
    color: white;
}

#loginBox h3{
    font-weight: 700;
}

.ms-signin-btn {
    cursor: pointer;
    outline: 0;
    box-shadow: unset !important;
}