.cohost {
    font-size: 0.9em !important;
    font-weight: 400 !important;
    cursor: pointer;
    opacity: 1;
    transition: padding-right 0s, background-color 0.2s, color 0.2s;
    background-color: transparent !important;
    color: black !important;
    user-select: none;
    padding-right: 20px !important;
    position: relative;
    margin: 5px;
}

.cohost:hover {
    opacity: 1;
    background-color: crimson !important;
    color: white !important;
    /* padding-right: 0px !important; */
}

.cohost::after {
    color: crimson;
    content: "";
    transition: color 0.4s;
}

.cohost:hover::after, .cohost:active::after {
    content: " x";
    font-size: 1.5em;
    font-weight: bold;
    color: rgb(255, 255, 255);
    position: absolute;
    right: 6px;
    top: -1px;
}

.cohost:active {
    opacity: 1;
    background-color: rgb(150, 15, 42) !important;
    color: white !important;
    transition: 0s;
}

.add {
    cursor: pointer;
    opacity: 0.7;
    transition: 0.2s;
    font-size: 22px !important;
    padding: 0px 6px 3px 6px !important;
    user-select: none;
}

.add:hover {
    opacity: 1;
}

.add:active {
    opacity: 1;
    transition: 0s;
    background-color: darkgreen !important;
}