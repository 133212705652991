.warning-alert .accordion-button{
    background-color: orange;
    color: white;
}

.warning-alert .accordion-button:not(.collapsed){
    background-color: rgb(255, 244, 148);
    color: orangered;
}

.danger-alert .accordion-button{
    background-color: rgb(151, 23, 0);
    color: white;
}

.danger-alert .accordion-button:not(.collapsed){
    background-color: rgb(255, 164, 148);
    color: darkred;
}

table#scheduleTable td:hover {
    background-color: #00000022;
    cursor: pointer;
}

table#scheduleTable td.slot-empty {
    background-color: darkred !important;
    color: white;
}

table#scheduleTable td.slot-empty:hover {
    background-color: #8b0000cc !important;
}

table#scheduleTable td.slot-overlap {
    background-color: orange !important;
    color: white;
}

table#scheduleTable td.slot-overlap:hover {
    background-color: #ffa500cc !important;
}

.schedule-header {
    display: flex;
    justify-content: space-between;
    align-items: center; 
    margin-bottom: 1rem; 
  }

.timeAwayModalButton {
    margin-left: 10px;
    padding: 2px 8px;
    border: 2px solid rgb(131, 7, 32);
    background-color: crimson;
    border-radius: 10px;
    color: white;
    cursor: pointer;
}

.timeAwaySave {
    margin-left: 10px;
    padding: 2px 8px;
    border: 2px solid rgb(7, 131, 32);
    background-color: green;
    border-radius: 10px;
    color: white;
    cursor: pointer;
}

.timeAwayCancel {
    margin-left: 10px;
    padding: 2px 8px;
    border: 2px solid rgb(131, 7, 32);
    background-color: crimson;
    border-radius: 10px;
    color: white;
    cursor: pointer;
}