.footerCredits {
    position: fixed;
    bottom: 0;
    text-align: center;
    opacity: 0.5;
    transition: 0.6s;
    cursor: default;
}

.footerCredits:hover {
    opacity: 1;
}