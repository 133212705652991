.profilePicture {
    height: 48px;
    width: 48px;
}

.navbar-brand a {
    color: crimson !important;
    cursor: pointer;
    text-decoration: none;
}

.navbar-brand, .nav-link, .dropdown-toggle, .dropdown-item{
    font-weight: 600 !important;
}