p {
    font-size: 20px;
}

.card {
    margin-bottom: 20px;
}

#profileDetails > div {
    margin-top: 5px;
    margin-bottom: 5px;
}

#profileDetails label {
    padding: 0.375rem .75rem;
}