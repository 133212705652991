th.sortable {
    cursor: pointer;
    position: relative;
    padding-right: 35px !important;
}

th .sortButton {
    cursor: pointer;
    opacity: 0;
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    transition: 0.2s;
    height: 24px;
}

th:hover .sortButton {
    opacity: 0.5;
}

th .sortButton.active {
    opacity: 1;
}